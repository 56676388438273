<template>
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">Ventas</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">Detalle de Ventas</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-6 lg:px-8 border-2">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row mb-4"
    >
      <h1>DETALLE DE VENTA</h1>
    </div>
    <div class="grid border-bottom-3" style="background-color: #f8f9fa">
      <div class="col-6">
        <p class="mb-0">
          <strong>RAZ&Oacute;N SOCIAL: </strong>
          <span>{{ venta.razon_social }}</span>
        </p>
        <p class="mb-0">
          <strong>NIT/CI: </strong> <span>{{ venta.nit }}</span>
        </p>
      </div>
      <div class="col-6">
        <p class="mb-0">
          <strong>FECHA VENTA: </strong>
          <span>{{ venta.fecha_venta }}</span>
        </p>
        <p class="mb-0">
          <strong>NRO. VENTA: </strong> <span>{{ venta.id }}</span>
        </p>
        <!-- <p class="mb-0">
          <strong>Usuario: </strong>
          <span>{{ venta.caja.nombre_user }}</span>
        </p> -->
      </div>
    </div>
    <div class="grid mt-4">
      <DataTable ref="ventas" :value="productos" class="col-12">
        <Column field="descripcion" header="DESCRIPCION">
          {{ producto.descripcion }}
        </Column>
        <Column field="pivot.precio" header="PRECIO UNITARIO">
          <template #body="slotProps">
            {{ convertirNumeroGermanicFormat(slotProps.data.pivot.precio) }}
          </template>
        </Column>
        <Column field="pivot.cantidad" header="CANTIDAD">
          <template #body="slotProps">
            {{ convertirNumeroGermanicFormat(slotProps.data.pivot.cantidad) }}
          </template>
        </Column>
        <Column field="pivot.descuento_neto" header="DESCUENTO">
          <template #body="slotProps">
            {{ convertirNumeroGermanicFormat(slotProps.data.pivot.descuento_neto) }}
          </template>
        </Column>
        <Column header="TOTAL">
          <template #body="slotProps">
            {{
              convertirNumeroGermanicFormat(
                slotProps.data.pivot.cantidad * slotProps.data.pivot.precio -
                  slotProps.data.pivot.descuento_neto
              )
            }}
          </template>
        </Column>
        <template #footer>
          <div class="text-lg flex align-items-end justify-content-end">
            <h5>
              <strong>SUBTOTAL Bs:</strong>
              {{ convertirNumeroGermanicFormat(subtotal) }}
            </h5>
          </div>
          <div class="text-lg flex align-items-end justify-content-end">
            <h5>
              <strong>DESCUENTO Bs:</strong>
              {{ convertirNumeroGermanicFormat(venta.descuento) }}
            </h5>
          </div>
          <div class="text-lg flex align-items-end justify-content-end">
            <h5>
              <strong>TOTAL CANCELAR Bs :</strong>
              {{ convertirNumeroGermanicFormat(venta.total_cancelar) }}
            </h5>
          </div>
        </template>
      </DataTable>
    </div>
    <div class="grid mt-2 justify-content-end">
      <Button @click="goBack">
        <i class="pi pi-arrow-circle-left mr-1"></i>
        &nbsp;Atr&aacute;s
      </Button>
      <Button
        label="Imprimir"
        icon="pi pi-file-pdf"
        class="p-button-danger ml-2"
        v-tooltip.top="'Imprimir PDF'"
        @click="imprimirVenta(venta.id)"
        :disabled="imprimiendo"
        :loading="imprimiendo"
      >
      </Button>
    </div>
  </div>
</template>

<script>
import VentaService from "@/service/VentaService";
export default {
  data() {
    return {
      venta: {},
      venta_id: this.$route.params.id,
      productos: [],
      imprimiendo: false,
    };
  },
  ventaService: null,
  created() {
    this.ventaService = new VentaService();
  },
  mounted() {
    this.cargarVenta();
  },
  computed: {
    subtotal() {
      let subtotal = 0;
      this.productos.forEach((producto) => {
        subtotal +=
          producto.pivot.precio * producto.pivot.cantidad
      });
      return subtotal;
    },
  },
  methods: {
    imprimirVenta(id) {
      if (this.imprimiendo) {
        return;
      }
      this.imprimiendo = true;
      this.ventaService.imprimirVenta(id).then(() => {
        this.imprimiendo = false;
      });
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
    cargarVenta() {
      this.ventaService.getVenta(this.venta_id).then((response) => {
        this.venta = response.venta;
        this.productos = this.venta.productos;
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>